import axios from 'axios';
/**
 * Survey Services
 */

// Function to fetch region table data
const saveSurveyApi = async (userData: any) => {
    try {
        const response = await axios.post(
            "save-survey", userData
        );

        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const checkEmail = async (userData: any) => {
    try {
        const response = await axios.post(
            "check-email", userData
        );

        return response;
    }
    catch (error: any) {
        return error?.response;
    }
};


const surveyServices = {
    saveSurveyApi,
    checkEmail
}

// Export the SurveyServices class for use in other parts of the application.
export default surveyServices;
