import {useState } from "react";
import { useAppDispatch, useAppSelector } from "store/redux.hooks";
import * as yup from "yup";
import { useFormik } from "formik";
import surveyServices from "store/survey/surveyServices";
import { getErrorMessage } from "utils";
import { toast } from "react-toastify";

import {
    saveSurvey
} from "store/survey/surveySlice";



/**
 * A custom hook that contains all the states and functions for the surveyController
 */
const SurveyController = () => {
    const dispatch = useAppDispatch();
    const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isLoadingFormSubmitDto, setIsLoadingFormSubmitDto] = useState(false);
    const {
        isLoadingFormSubmit
    } = useAppSelector((state: any) => state.survey);

    const schemaUser = yup.object().shape(
        {
            firstName: yup
                .string()
                .max(20, "First Name should not exceed 20 characters")
                .matches(/^[A-Za-z\s]+$/, 'First name should only contain letters and spaces')
                .required("First Name should not be empty"),
            lastName: yup
                .string()
                .max(20, "Last Name should not exceed 20 characters")
                .matches(/^[A-Za-z\s]+$/, 'Last name should only contain letters and spaces')
                .required("Last Name should not be empty"),
            email: yup
                .string()
                .email("Email must be a valid email")
                .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email must be a valid email')
                .required("Email should not be empty"),
            phone: yup.string().min(10,"Phone Number should not be less than 10 digits").max(10,"Phone Number should not exceed 10 digits").nullable().required("Phone Number should not be empty"),
            title: yup
                .string()
                .max(20, "Role should not exceed 20 characters")
                .matches(/^[A-Za-z\s]+$/, 'Role should only contain letters and spaces')
                .required("Role should not be empty"),
            carrierName: yup
                .string()
                .max(40, "Carrier Name should not exceed 40 characters")
                .required("Carrier Name should not be empty"),

            carrierCode: yup
                .string().min(4, "Carrier SCAC should not be less than 4 characters").max(4, "Carrier SCAC should not exceed 4 characters")
                .matches(/^[A-Za-z]+$/, 'Carrier SCAC should only contain letters')
                .required("Carrier SCAC should not be empty"),
        });
    // Initialize form fields for user profile.
    let _FieldsUser = {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        title: "",
        carrierName: "",
        carrierCode: "",
    };



    // Operational Information
    const schemaOperationalInformation = yup.object().shape(
        {
            freightVolume: yup
                .string().max(9,"Freight Volume should be upto 9 digits").required("Freight volume should not be empty"),
            freightLanes: yup
                .string().max(9,"Freight Lane should be upto 9 digits")
                .required("Freight lanes should not be empty"),
            origin: yup
                .string()
                .required("Origin/Destination should not be empty"),
            totalWeight: yup
                .string()
                .required("Total weight should not be empty"),
            totalLoaded: yup
                .string()
                .required("Total loaded should not be empty"),
            totalUnloaded: yup
                .string()
                .required("Total unloaded miles should not be empty"),
            milesPerGallon: yup
                .string()
                .required("Miles per gallon should not be empty"),
            typeOfFuel: yup
                .string()
                .required("Type of fuel should not be empty"),
            fuelName: yup.string(),
            overallTime: yup
                .string()
                .required("Overall time should not be empty"),


        });
    // Initialize form fields for user profile.
    let _FieldsOperationalInformation = {
        freightVolume: "",
        freightLanes: "",
        origin: "yes",
        totalWeight: "yes",
        totalLoaded: "yes",
        totalUnloaded: "yes",
        milesPerGallon: "yes",
        typeOfFuel: "yes",
        fuelName: "",
        assetVINNumber: "yes",
        makeModal: "yes",
        assetManufactureYear: "yes",
        eVAsset: "yes",
        overallLength: "yes",
        overallTime: "yes"
    };



    const handleSubmitUserForm = async (event: any) => {
        setIsLoadingFormSubmitDto(true)
        await surveyServices.checkEmail({ "email": formikUser?.values?.email }).then((res) => {
            if (res?.status === 200) {
                setIsSurveySubmitted(true)
            } else {
                toast.error(res?.data?.message)
            }
            setIsLoadingFormSubmitDto(false)
        }).catch((error: any) => {
            getErrorMessage(error)
            setIsLoadingFormSubmitDto(false)

        })
        // setIsSurveySubmitted(true)
    };

    const handleSubmitOperationalInformation = async (event: any) => {
        dispatch(saveSurvey({dto: {
            "email": formikUser?.values?.email,
            "firstName": formikUser?.values?.firstName,
            "lastName": formikUser?.values?.lastName,
            "carrierName": formikUser?.values?.carrierName,
            "phone": `+1${formikUser?.values?.phone}`,
            "scac": formikUser?.values?.carrierCode,
            "role": formikUser?.values?.title,
            "freightVolume": formikOperationalInformation?.values?.freightVolume,
            "operationalFreightLanes": formikOperationalInformation?.values?.freightLanes,
            "originDest": formikOperationalInformation?.values?.origin === "yes" ? 1 : 0,
            "weight": formikOperationalInformation?.values?.totalWeight === "yes" ? 1 : 0,
            "totalLoadedMiles": formikOperationalInformation?.values?.totalLoaded === "yes" ? 1 : 0,
            "totalUnloadedMiles": formikOperationalInformation?.values?.totalUnloaded === "yes" ? 1 : 0,
            "milesPerGallon": formikOperationalInformation?.values?.milesPerGallon === "yes" ? 1 : 0,
            "fuelTypeInfo": formikOperationalInformation?.values?.typeOfFuel === "yes" ? 1 : 0,
            "assetVinNum": formikOperationalInformation?.values?.assetVINNumber === "yes" ? 1 : 0,
            "assetMakeModel": formikOperationalInformation?.values?.makeModal === "yes" ? 1 : 0,
            "assetManufactureYear": formikOperationalInformation?.values?.assetManufactureYear === "yes" ? 1 : 0,
            "evAsset": formikOperationalInformation?.values?.eVAsset === "yes" ? 1 : 0,
            "length": formikOperationalInformation?.values?.overallLength === "yes" ? 1 : 0,
            "time": formikOperationalInformation?.values?.overallTime === "yes" ? 1 : 0,
        }, fuc: setShowSuccessModal}));
    };

    const formikOperationalInformation = useFormik({
        initialValues: _FieldsOperationalInformation,
        validationSchema: schemaOperationalInformation,
        onSubmit: handleSubmitOperationalInformation,
    });


    const formikUser = useFormik({
        initialValues: _FieldsUser,
        validationSchema: schemaUser,
        onSubmit: handleSubmitUserForm,
    });
    const handleClear = () => {
        setShowSuccessModal(false)
        setIsSurveySubmitted(false)
        formikUser.resetForm()
        formikOperationalInformation.resetForm()
    }
    return {
        isSurveySubmitted,
        formikUser,
        showSuccessModal,
        setShowSuccessModal,
        setIsSurveySubmitted,
        formikOperationalInformation,
        isLoadingFormSubmitDto,
        isLoadingFormSubmit,
        handleClear
    };
};

// Exporting the custom hook for use in other components
export default SurveyController;
