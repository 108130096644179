import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';


import surveyReducer from "./survey/surveySlice"
// Array to hold middleware
const middleware: any = [];

// Add logger middleware to the array
if (process.env.REACT_APP_IS_DEV) {
  middleware.push(logger);
}

const persistConfig = {
  key: 'root',
  storage,
  
}

const surveyDto = persistReducer(persistConfig, surveyReducer)
// Configure the Redux store
const store = configureStore({
  reducer: {
    
    survey: surveyDto
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    // Disable checks for immutability and serializability for improved performance
    immutableCheck: false,
    serializableCheck: false
  }).concat(middleware),
});

export const persistor = persistStore(store)


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: { auth: AuthState, dashboard: DashboardState, commonData: CommonState, region: RegionState, home: HomeState }
export type AppDispatch = typeof store.dispatch;

export default store;
