import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "utils";
import surveyServices from "./surveyServices";
import { SurveyStateInterface } from "./surveyInterface";

const initialState: SurveyStateInterface = {
  formSubmit: false,
  isLoadingFormSubmit: false
};


export const saveSurvey = createAsyncThunk(
  "save/saveSurvey",
  async (userData: any, thunkApi) => {
    try {
      await surveyServices.saveSurveyApi(userData?.dto);
      userData?.fuc(true)
    } catch (error: any) {
      userData?.fuc(false)
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const surveyReducer = createSlice({
  name: "survey-details",
  initialState,
  reducers: {
    resetSurveyData: () => initialState,
  },

  extraReducers: (builder) => {
    builder


      .addCase(saveSurvey.pending, (state) => {
        state.isLoadingFormSubmit = true
        state.formSubmit = false;
      })
      .addCase(saveSurvey.fulfilled, (state, action) => {
        console.log("ssssssssssssssssssssssssss", action)
        state.isLoadingFormSubmit = false
        state.formSubmit = true;
      })
      .addCase(saveSurvey.rejected, (state, _) => {
        state.isLoadingFormSubmit = false
        state.formSubmit = null;
      })

  },
});

export default surveyReducer.reducer;
