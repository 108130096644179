import state from "./state.json";
export const pageSizeList = [10, 20, 30, 40, 50];
export const cardViewPageSizeList = [12, 24, 36, 48, 60];

export const listCode = state;

export const aboutUsLink = "https://smartfreightcentre.org/en/about-sfc/about-us/";

export const evDashboardLink = process.env.REACT_APP_URL;
export const domainUrl = process.env.REACT_APP_DOMAIN_URL;

//   Get tegion list
export const regionList = [
    {
        id: 5,
        modalShiftpercentage: 35,
        carrierShiftpercentage: 20,
        alternativeShiftpercentage: 5,
        marker: {
            lat: 39.7684,
            lng: -86.1581,
        },
        wayPoint: [
            {
                locationName: "Mill street charlottesville, Usa",
                lat: 39.7684,
                log: -86.1581,
            },
            {
                locationName: "Charlottesville, USA",
                lat: 38.0293,
                log: -78.4767,
            },
        ],
    }
];

export const evDashboardEnable = false

export enum Provider {
    evNetwork = 4,
    optimus = 5
}

export const emailDetail = {
    "email": process.env.REACT_APP_DUMMY_EMAIL,
    "password": process.env.REACT_APP_DUMMY_PASSWORD
}

export const companySlug = {
    pep: "PEP",
    adm: "ADM",
    lw: "LW"
}