import SurveyRoute from './routes/surveyRoute';

const App = () => {
  return (
    <div data-testid="app-component">
        <SurveyRoute />
    </div>

  );
}

export default App;
