import { Col, Form, FormGroup, Input, Row, Modal, ModalHeader, ModalBody, } from "reactstrap"
import user from "../../assets/images/survey/user.svg"
import message from "../../assets/images/survey/message.svg"
import truck from "../../assets/images/survey/truck.svg"
import arrow from "../../assets/images/survey/arrow.svg"
import userRole from "../../assets/images/survey/user-role.svg"
import call from "../../assets/images/survey/call.svg"
import scope from "../../assets/images/survey/scope23.svg"
import pepsico from "../../assets/images/survey/pepsico.svg"
import submit from "../../assets/images/survey/submit.svg"
import SurveyController from "./surveyController"
import Loader from "component/loader/Loader"
import { numberOnly } from "utils";

const SurveyView = (args: any) => {

    const {
        isSurveySubmitted,
        formikUser,
        showSuccessModal,
        handleClear,
        setIsSurveySubmitted,
        formikOperationalInformation,
        isLoadingFormSubmitDto,
        isLoadingFormSubmit
    } = SurveyController();
    return (
        <section className="insight_top_wrapper">
            <div className="mainDashboard position-relative">
                <Loader isLoading={[isLoadingFormSubmitDto, isLoadingFormSubmit]} />
                <div className="w-100">
                    <section className="surveyForm-wrapper pb-5">
                        <div className="container-fluid px-0">
                            <div className="survey-banner">
                                <div className="d-flex justify-content-between ps-3">
                                    <img src={scope} alt="profile" className="scope" />
                                    <img src={pepsico} alt="profile" className="pepsi" />
                                </div>

                                <div className="container">
                                    <div className="ps-5 banner-txt">
                                        <h4 className="font-xxl-44 font-xl-32 font-lg-28 font-24 fw-semibold">
                                            Scope23 Carrier Survey
                                        </h4>
                                        <p className="font-20">Start your journey towards decarbonization.</p>
                                    </div>

                                </div>
                            </div>
                            {!isSurveySubmitted ? (
                                <div className="container">
                                    <div className="mt-xxl-4">
                                        <div className="d-flex align-items-center gap-3 mb-4">
                                            <img src={arrow} alt="profile" />
                                            <h4 className="font-24 fw-semibold mb-0">
                                                Basic Information
                                            </h4>
                                        </div>
                                        <Form
                                            data-testid="edit-user-fields"
                                            className="d-flex flex-wrap gap-3"
                                            onSubmit={formikUser.handleSubmit}
                                        >
                                            <Row className="g-4">
                                                <Col lg="6">
                                                    <div className="position-relative">
                                                        <h4 className="font-16 mb-3 fw-medium">
                                                            First Name<span className="text-danger">*</span>
                                                        </h4>
                                                        {/* Input field for Last Name */}

                                                        <FormGroup>
                                                            <img src={user} alt="profile" />
                                                            <Input
                                                                id="exampleEmail"
                                                                name="firstName"
                                                                placeholder="Enter your First Name"
                                                                onChange={formikUser.handleChange}
                                                                value={formikUser.values.firstName}
                                                            />
                                                            {formikUser.touched.firstName &&
                                                                formikUser.errors.firstName && (
                                                                    <span style={{ color: "red", fontSize: "14px" }}>
                                                                        {formikUser.errors.firstName}
                                                                    </span>
                                                                )}
                                                        </FormGroup>
                                                    </div>

                                                </Col>
                                                <Col lg="6">
                                                    <div className="position-relative">
                                                        <h4 className="font-16 mb-3 fw-medium">
                                                            Last Name<span className="text-danger">*</span>
                                                        </h4>
                                                        {/* Input field for Last Name */}

                                                        <FormGroup>
                                                            <img src={user} alt="profile" />
                                                            <Input
                                                                id="exampleEmail"
                                                                name="lastName"
                                                                placeholder="Enter your Last Name"
                                                                onChange={formikUser.handleChange}
                                                                value={formikUser.values.lastName}
                                                            />
                                                            {formikUser.touched.lastName &&
                                                                formikUser.errors.lastName && (
                                                                    <span style={{ color: "red", fontSize: "14px" }}>
                                                                        {formikUser.errors.lastName}
                                                                    </span>
                                                                )}
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                <Col lg="6">
                                                    <div className="position-relative">
                                                        <h4 className="font-16 mb-3 fw-medium">
                                                            User Role<span className="text-danger">*</span>
                                                        </h4>
                                                        {/* Input field for Last Name */}

                                                        <FormGroup>
                                                            <img src={userRole} alt="profile" />
                                                            <Input
                                                                id="exampleEmail"
                                                                name="title"
                                                                placeholder="Enter your role "
                                                                onChange={formikUser.handleChange}
                                                                value={formikUser.values.title}
                                                            />
                                                            {formikUser.touched.title &&
                                                                formikUser.errors.title && (
                                                                    <span style={{ color: "red", fontSize: "14px" }}>
                                                                        {formikUser.errors.title}
                                                                    </span>
                                                                )}
                                                        </FormGroup>
                                                    </div>

                                                </Col>


                                                <Col lg="6">
                                                    <div className="position-relative">
                                                        <h4 className="font-16 mb-3 fw-medium">
                                                            Phone Number<span className="text-danger">*</span>
                                                        </h4>
                                                        {/* Input field for Last Name */}

                                                        <FormGroup>
                                                            <img src={call} alt="profile" />
                                                            <span className="countryCode">+1</span>  <Input
                                                                id="exampleEmail"
                                                                name="phone"
                                                                placeholder="Enter your Phone Number"
                                                                className="phoneNumber"
                                                                onChange={formikUser.handleChange}
                                                                onKeyDown={(e: any) => numberOnly(e)}
                                                                value={formikUser.values.phone}
                                                            />
                                                            {formikUser.touched.phone &&
                                                                formikUser.errors.phone && (
                                                                    <span style={{ color: "red", fontSize: "14px" }}>
                                                                        {formikUser.errors.phone}
                                                                    </span>
                                                                )}
                                                        </FormGroup>
                                                    </div>

                                                </Col>

                                                <Col lg="6">
                                                    <div className="position-relative">
                                                        <h4 className="font-16 mb-3 fw-medium">
                                                            Email Address<span className="text-danger">*</span>
                                                        </h4>
                                                        {/* Input field for Last Name */}
                                                        <FormGroup>
                                                            <img src={message} alt="profile" className="message" />

                                                            <Input
                                                                id="exampleEmail"
                                                                name="email"
                                                                placeholder="Enter Email Address"
                                                                onChange={formikUser.handleChange}
                                                                value={formikUser.values.email}
                                                            />
                                                            {formikUser.touched.email &&
                                                                formikUser.errors.email && (
                                                                    <span style={{ color: "red", fontSize: "14px" }}>
                                                                        {formikUser.errors.email}
                                                                    </span>
                                                                )}
                                                        </FormGroup>
                                                    </div>

                                                </Col>

                                                <Col lg="6">
                                                    <div className="position-relative">
                                                        <h4 className="font-16 mb-3 fw-medium">
                                                            Carrier Name<span className="text-danger">*</span>
                                                        </h4>
                                                        {/* Input field for Last Name */}

                                                        <FormGroup>
                                                            <img src={truck} alt="profile" />
                                                            <Input
                                                                id="exampleEmail"
                                                                name="carrierName"
                                                                placeholder="Enter Carrier Name "
                                                                onChange={formikUser.handleChange}
                                                                value={formikUser.values.carrierName}
                                                            />
                                                            {formikUser.touched.carrierName &&
                                                                formikUser.errors.carrierName && (
                                                                    <span style={{ color: "red", fontSize: "14px" }}>
                                                                        {formikUser.errors.carrierName}
                                                                    </span>
                                                                )}
                                                        </FormGroup>
                                                    </div>

                                                </Col>

                                                <Col lg="6">
                                                    <div className="position-relative">
                                                        <h4 className="font-16 mb-3 fw-medium">
                                                            Carrier SCAC<span className="text-danger">*</span>
                                                        </h4>
                                                        {/* Input field for Last Name */}

                                                        <FormGroup>
                                                            <img src={truck} alt="profile" />
                                                            <Input
                                                                id="exampleEmail"
                                                                name="carrierCode"
                                                                placeholder="Enter SCAC code"
                                                                onChange={formikUser.handleChange}
                                                                value={formikUser.values.carrierCode}
                                                            />
                                                            {formikUser.touched.carrierCode &&
                                                                formikUser.errors.carrierCode && (
                                                                    <span style={{ color: "red", fontSize: "14px" }}>
                                                                        {formikUser.errors.carrierCode}
                                                                    </span>
                                                                )}
                                                        </FormGroup>
                                                    </div>

                                                </Col>
                                            </Row>
                                            <div className="d-flex justify-content-end mt-3 w-100">
                                                <button
                                                    type="submit"
                                                    className="btn-blue font-16 d-flex gap-2"
                                                    data-testid="update-btn">
                                                    Next <span> <img src={arrow} alt="logo" /></span>
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>) : (
                                <div className="operational-info">
                                    <div className="container">
                                        <div className="mt-xxl-4">
                                            <div className="d-flex align-items-center gap-3 mb-4">
                                                <img src={arrow} alt="profile" />
                                                <h4 className="font-24 fw-semibold mb-0">
                                                    Operational Information
                                                </h4>
                                            </div>
                                            <Form
                                                data-testid="edit-user-fields"
                                                className="d-flex flex-wrap gap-3"
                                                onSubmit={formikOperationalInformation.handleSubmit}
                                            >
                                                <Row className="g-4 w-100">
                                                    <Col lg="6">
                                                        <h4 className="font-16 mb-3 fw-medium">
                                                            What is your annual freight volume for Pepsi?<span className="fw-normal font-14"> (Number of loads)</span><span className="text-danger">*</span>
                                                        </h4>
                                                        {/* Input field for Last Name */}
                                                        <FormGroup>
                                                            <Input
                                                                id="exampleEmail"
                                                                name="freightVolume"
                                                                placeholder="Enter the annual freight volume for pepsi"
                                                                onChange={formikOperationalInformation.handleChange}
                                                                value={formikOperationalInformation.values.freightVolume}
                                                                onKeyDown={(e: any) => numberOnly(e)}

                                                            />
                                                            {formikOperationalInformation.touched.freightVolume &&
                                                                formikOperationalInformation.errors.freightVolume && (
                                                                    <span style={{ color: "red", fontSize: "14px" }}>
                                                                        {formikOperationalInformation.errors.freightVolume}
                                                                    </span>
                                                                )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <h4 className="font-16 mb-3 fw-medium">
                                                            How many freight lanes do you operate for Pepsi?<span className="text-danger">*</span>
                                                        </h4>
                                                        {/* Input field for Last Name */}
                                                        <FormGroup>
                                                            <Input
                                                                id="exampleEmail"
                                                                name="freightLanes"
                                                                placeholder="Enter the number of lanes operated for pepsi"
                                                                onChange={formikOperationalInformation.handleChange}
                                                                value={formikOperationalInformation.values.freightLanes}
                                                                onKeyDown={(e: any) => numberOnly(e)}

                                                            />
                                                            {formikOperationalInformation.touched.freightLanes &&
                                                                formikOperationalInformation.errors.freightLanes && (
                                                                    <span style={{ color: "red", fontSize: "14px" }}>
                                                                        {formikOperationalInformation.errors.freightLanes}
                                                                    </span>
                                                                )}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <h4 className="font-18 mb-2 fw-medium w-100">
                                                    For every load, are you able to provide the following information (Yes/No)?
                                                </h4>

                                                <div className="border rounded-2 w-100">
                                                    <div className="p-3 border-bottom mb-3">
                                                        <h4 className="font-18 mb-0 fw-medium w-100">
                                                            Essential Details<span className="text-danger">*</span>
                                                        </h4>
                                                    </div>
                                                    <div className="p-3 pb-4">
                                                        <Row className="g-4">
                                                            <Col xl="3" lg="4" md="4" xs="12">
                                                                <div>
                                                                    <h4 className="font-14 mb-2 fw-medium">
                                                                        Origin/Destination
                                                                    </h4>
                                                                    <div className="d-flex gap-3">
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="origin"
                                                                                checked={formikOperationalInformation.values.origin === 'yes'}
                                                                                onChange={formikOperationalInformation.handleChange}

                                                                            />
                                                                            {' '}Yes
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="no"
                                                                                name="origin"
                                                                                checked={formikOperationalInformation.values.origin === 'no'}
                                                                                onChange={formikOperationalInformation.handleChange}
                                                                            />
                                                                            {' '}No
                                                                        </FormGroup>

                                                                    </div>
                                                                    {formikOperationalInformation.touched.origin &&
                                                                        formikOperationalInformation.errors.origin && (
                                                                            <span style={{ color: "red", fontSize: "14px" }}>
                                                                                {formikOperationalInformation.errors.origin}
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            </Col>
                                                            <Col xl="3" lg="4" md="4" xs="12">
                                                                <div>
                                                                    <h4 className="font-14 mb-2 fw-medium">
                                                                        Total weight in tons
                                                                    </h4>
                                                                    <div className="d-flex gap-3">
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="totalWeight"
                                                                                checked={formikOperationalInformation.values.totalWeight === 'yes'}
                                                                                onChange={formikOperationalInformation.handleChange}

                                                                            />
                                                                            {' '}Yes
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="no"
                                                                                name="totalWeight"
                                                                                checked={formikOperationalInformation.values.totalWeight === 'no'}
                                                                                onChange={formikOperationalInformation.handleChange}
                                                                            />
                                                                            {' '}No
                                                                        </FormGroup>

                                                                    </div>
                                                                    {formikOperationalInformation.touched.totalWeight &&
                                                                        formikOperationalInformation.errors.totalWeight && (
                                                                            <span style={{ color: "red", fontSize: "14px" }}>
                                                                                {formikOperationalInformation.errors.totalWeight}
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            </Col>
                                                            <Col xl="3" lg="4" md="4" xs="12">
                                                                <div>
                                                                    <h4 className="font-14 mb-2 fw-medium">
                                                                        Total loaded miles
                                                                    </h4>
                                                                    <div className="d-flex gap-3">
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="totalLoaded"
                                                                                checked={formikOperationalInformation.values.totalLoaded === 'yes'}
                                                                                onChange={formikOperationalInformation.handleChange}

                                                                            />
                                                                            {' '}Yes
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="no"
                                                                                name="totalLoaded"
                                                                                checked={formikOperationalInformation.values.totalLoaded === 'no'}
                                                                                onChange={formikOperationalInformation.handleChange}
                                                                            />
                                                                            {' '}No
                                                                        </FormGroup>

                                                                    </div>
                                                                    {formikOperationalInformation.touched.totalLoaded &&
                                                                        formikOperationalInformation.errors.totalLoaded && (
                                                                            <span style={{ color: "red", fontSize: "14px" }}>
                                                                                {formikOperationalInformation.errors.totalLoaded}
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            </Col>
                                                            <Col xl="3" lg="4" md="4" xs="12">
                                                                <div>
                                                                    <h4 className="font-14 mb-2 fw-medium">
                                                                        Total unloaded miles
                                                                    </h4>
                                                                    <div className="d-flex gap-3">
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="totalUnloaded"
                                                                                checked={formikOperationalInformation.values.totalUnloaded === 'yes'}
                                                                                onChange={formikOperationalInformation.handleChange}

                                                                            />
                                                                            {' '}Yes
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="no"
                                                                                name="totalUnloaded"
                                                                                checked={formikOperationalInformation.values.totalUnloaded === 'no'}
                                                                                onChange={formikOperationalInformation.handleChange}
                                                                            />
                                                                            {' '}No
                                                                        </FormGroup>

                                                                    </div>
                                                                    {formikOperationalInformation.touched.totalUnloaded &&
                                                                        formikOperationalInformation.errors.totalUnloaded && (
                                                                            <span style={{ color: "red", fontSize: "14px" }}>
                                                                                {formikOperationalInformation.errors.totalUnloaded}
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            </Col>
                                                            <Col xl="3" lg="4" md="4" xs="12">
                                                                <div>
                                                                    <h4 className="font-14 mb-2 fw-medium">
                                                                        Miles per gallon
                                                                    </h4>
                                                                    <div className="d-flex gap-3">
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="milesPerGallon"
                                                                                checked={formikOperationalInformation.values.milesPerGallon === 'yes'}
                                                                                onChange={formikOperationalInformation.handleChange}

                                                                            />
                                                                            {' '}Yes
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="no"
                                                                                name="milesPerGallon"
                                                                                checked={formikOperationalInformation.values.milesPerGallon === 'no'}
                                                                                onChange={formikOperationalInformation.handleChange}
                                                                            />
                                                                            {' '}No
                                                                        </FormGroup>

                                                                    </div>
                                                                    {formikOperationalInformation.touched.milesPerGallon &&
                                                                        formikOperationalInformation.errors.milesPerGallon && (
                                                                            <span style={{ color: "red", fontSize: "14px" }}>
                                                                                {formikOperationalInformation.errors.milesPerGallon}
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            </Col>
                                                            <Col xl="3" lg="4" md="4" xs="12">
                                                                <div>
                                                                    <h4 className="font-14 mb-2 fw-medium">
                                                                        Type of Fuel (Example B99)
                                                                    </h4>

                                                                    <div className="d-flex gap-3">
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="typeOfFuel"
                                                                                checked={formikOperationalInformation.values.typeOfFuel === 'yes'}
                                                                                onChange={formikOperationalInformation.handleChange}

                                                                            />
                                                                            {' '}Yes
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="no"
                                                                                name="typeOfFuel"
                                                                                checked={formikOperationalInformation.values.typeOfFuel === 'no'}
                                                                                onChange={formikOperationalInformation.handleChange}
                                                                            />
                                                                            {' '}No
                                                                        </FormGroup>

                                                                    </div>
                                                                    {formikOperationalInformation.touched.typeOfFuel &&
                                                                        formikOperationalInformation.errors.typeOfFuel && (
                                                                            <span style={{ color: "red", fontSize: "14px" }}>
                                                                                {formikOperationalInformation.errors.typeOfFuel}
                                                                            </span>
                                                                        )}

                                                                </div>
                                                            </Col>
                                                            <Col xl="3" lg="4" md="4" xs="12">
                                                                <div>
                                                                    <h4 className="font-14 mb-2 fw-medium">
                                                                        Overall time for haul
                                                                    </h4>
                                                                    <div className="d-flex gap-3">
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="overallTime"
                                                                                checked={formikOperationalInformation.values.overallTime === 'yes'}
                                                                                onChange={formikOperationalInformation.handleChange}

                                                                            />
                                                                            {' '}Yes
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="no"
                                                                                name="overallTime"
                                                                                checked={formikOperationalInformation.values.overallTime === 'no'}
                                                                                onChange={formikOperationalInformation.handleChange}
                                                                            />
                                                                            {' '}No
                                                                        </FormGroup>

                                                                    </div>
                                                                    {formikOperationalInformation.touched.overallTime &&
                                                                        formikOperationalInformation.errors.overallTime && (
                                                                            <span style={{ color: "red", fontSize: "14px" }}>
                                                                                {formikOperationalInformation.errors.overallTime}
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                <div className="border rounded-2 w-100">
                                                    <div className="p-3 border-bottom mb-3">
                                                        <h4 className="font-18 mb-0 fw-medium w-100">
                                                            Additional Details
                                                        </h4>
                                                    </div>
                                                    <div className="p-3 pb-4">
                                                        <Row className="g-4">
                                                            <Col xl="3" lg="4" md="4" xs="12">
                                                                <div>
                                                                    <h4 className="font-14 mb-2 fw-medium">
                                                                        Asset VIN Number
                                                                    </h4>
                                                                    <div className="d-flex gap-3">
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="assetVINNumber"
                                                                                checked={formikOperationalInformation.values.assetVINNumber === 'yes'}
                                                                                onChange={formikOperationalInformation.handleChange}

                                                                            />
                                                                            {' '}Yes
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="no"
                                                                                name="assetVINNumber"
                                                                                checked={formikOperationalInformation.values.assetVINNumber === 'no'}
                                                                                onChange={formikOperationalInformation.handleChange}
                                                                            />
                                                                            {' '}No
                                                                        </FormGroup>

                                                                    </div>

                                                                </div>
                                                            </Col>
                                                            <Col xl="3" lg="4" md="4" xs="12">
                                                                <div>
                                                                    <h4 className="font-14 mb-2 fw-medium">
                                                                        Asset Make/Model
                                                                    </h4>
                                                                    <div className="d-flex gap-3">
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="makeModal"
                                                                                checked={formikOperationalInformation.values.makeModal === 'yes'}
                                                                                onChange={formikOperationalInformation.handleChange}

                                                                            />
                                                                            {' '}Yes
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="no"
                                                                                name="makeModal"
                                                                                checked={formikOperationalInformation.values.makeModal === 'no'}
                                                                                onChange={formikOperationalInformation.handleChange}
                                                                            />
                                                                            {' '}No
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xl="3" lg="4" md="4" xs="12">
                                                                <div>
                                                                    <h4 className="font-14 mb-2 fw-medium">
                                                                        Asset Manufacture Year
                                                                    </h4>
                                                                    <div className="d-flex gap-3">
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="assetManufactureYear"
                                                                                checked={formikOperationalInformation.values.assetManufactureYear === 'yes'}
                                                                                onChange={formikOperationalInformation.handleChange}

                                                                            />
                                                                            {' '}Yes
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="no"
                                                                                name="assetManufactureYear"
                                                                                checked={formikOperationalInformation.values.assetManufactureYear === 'no'}
                                                                                onChange={formikOperationalInformation.handleChange}
                                                                            />
                                                                            {' '}No
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xl="3" lg="4" md="4" xs="12">
                                                                <div>
                                                                    <h4 className="font-14 mb-2 fw-medium">
                                                                        EV Asset
                                                                    </h4>
                                                                    <div className="d-flex gap-3">
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="eVAsset"
                                                                                checked={formikOperationalInformation.values.eVAsset === 'yes'}
                                                                                onChange={formikOperationalInformation.handleChange}

                                                                            />
                                                                            {' '}Yes
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="no"
                                                                                name="eVAsset"
                                                                                checked={formikOperationalInformation.values.eVAsset === 'no'}
                                                                                onChange={formikOperationalInformation.handleChange}
                                                                            />
                                                                            {' '}No
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xl="3" lg="4" md="4" xs="12">
                                                                <div>
                                                                    <h4 className="font-14 mb-2 fw-medium">
                                                                        Overall length of haul
                                                                    </h4>
                                                                    <div className="d-flex gap-3">
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="overallLength"
                                                                                checked={formikOperationalInformation.values.overallLength === 'yes'}
                                                                                onChange={formikOperationalInformation.handleChange}

                                                                            />
                                                                            {' '}Yes
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                value="no"
                                                                                name="overallLength"
                                                                                checked={formikOperationalInformation.values.overallLength === 'no'}
                                                                                onChange={formikOperationalInformation.handleChange}
                                                                            />
                                                                            {' '}No
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>

                                                <div className=" w-100">

                                                    <div className="p-3 pb-4">
                                                        <Row className="g-4">
                                                            <h4 className="font-18 mb-0 fw-medium w-100">
                                                                Note:

                                                            </h4>
                                                            <svg className="mx-width-svg" xmlns="
                                                            http://www.w3.org/2000/svg" width="96" height="2" viewBox="0 0 96 2" fill="none" style={{
                                                                width: "96;",
                                                                margin: "0"
                                                         
                                                            }}
                                                                
                                                              
                                                            ><path d="M0 1H45" stroke="#2263D5" ></path></svg>
                                                            <Col xl="12" >
                                                                <h4 className="font-16 mb-3 fw-medium">
                                                                    <span className="text-danger">*</span><span className="fw-normal font-14"> This information will only be used for backend emission calculations.</span>
                                                                </h4>
                                                                <h4 className="font-16 mb-3 fw-medium">
                                                                    <span className="text-danger">*</span><span className="fw-normal font-14"> This data will not be directly shared with Pepsico.</span>
                                                                </h4>
                                                            </Col>

                                                        </Row>
                                                    </div>
                                                </div>

                                                <div className="d-flex gap-3 justify-content-end mt-3 w-100">
                                                    <button
                                                        type="submit"
                                                        className="btn-blue-outline font-16 d-flex gap-2"
                                                        data-testid="update-btn"
                                                        onClick={() => setIsSurveySubmitted(false)}
                                                    >
                                                        <span> <img src={arrow} alt="logo" /></span>  Back
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn-blue font-16 d-flex gap-2"
                                                        data-testid="update-btn">
                                                        Submit
                                                    </button>
                                                </div>


                                            </Form>
                                        </div>
                                    </div>
                                    <Modal isOpen={showSuccessModal} toggle={() => handleClear()} {...args} className="successmodal">
                                        <ModalHeader toggle={() => handleClear()} className="border-0"></ModalHeader>
                                        <ModalBody className="text-center pt-0">
                                            <img src={submit} className="mb-3" alt="logo" />
                                            <h4 className="font-24 fw-semibold text-primary">
                                                Thank you for your submission.
                                            </h4>
                                            <p className="font-16">Your details are being processed.</p>
                                        </ModalBody>

                                    </Modal>
                                </div>

                            )}
                        </div>
                    </section>
                </div>
            </div>
        </section>
    )
}

export default SurveyView