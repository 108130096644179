import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import SurveyView from "pages/survey/SurveyView";
/**
 * Component that defines all the routes for the website
 */
const SurveyRoute = () => {
  
  return (
    <Router >
      <Routes>

        <Route path="/" element={<SurveyView />} />

      </Routes>
    </Router>
  );
};

export default SurveyRoute;
